import { createStore, applyMiddleware, compose } from 'redux'
import thunkMiddleware from 'redux-thunk'
import reducers from '../actions/rootReducer'
// import loggerMiddleware from '../../middlewares/loggerMiddleware'
// import { isDev } from '../../utils/serverProps'

const middlewareEnhancer = applyMiddleware(thunkMiddleware)
let composedEnhancers = compose(middlewareEnhancer)

try {
	if (window.__REDUX_DEVTOOLS_EXTENSION__) {
		composedEnhancers = compose(
			middlewareEnhancer,
			window.__REDUX_DEVTOOLS_EXTENSION__()
		)
	}
} catch (e) {
	console.log(e)
}

const store = createStore(reducers, undefined, composedEnhancers)

export { store }
