import axios from "axios";
import _get from "lodash/get";
import { URLS } from "../../constants";

const axiosInstance = axios.create({
  baseURL: URLS.NEXT_API
  /* other custom settings */
});

axiosInstance.defaults.timeout = 10000;
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.interceptors.request.use(function requestInterceptor(config) {
  // config.headers["authorization"] = `Bearer ${getJwtToken()}`;
  // config.headers["x-api-key"] = URLS.CORE_API_KEY;
  return config;
});

axiosInstance.interceptors.response.use(
  function(response) {
    return response;
  },
  function(error) {
    const responseCode = _get(error, "response.data.errors[0].code");

    if (responseCode === "UN_AUTHORIZED") {
      console.log(" ******* USER IS UNAUTORIZED ******* ");
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
