export const dummyData = {
    registerDriver: {},
    personal: {},
    personalAddress: [],
    general: {},
    license: {},
    violation: [],
    accident: [],
    military: {},
    employment: [],
    training: [],
    unemployment: [],
    mvr: {},
    hos: {},
    fmcsr: {},
    review: {}
};
