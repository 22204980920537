import * as TYPES from './actionTypes';
import { dummyData } from './dummyData';

const defaultState = {
  general: dummyData.general,
  physicalAddress: dummyData.physicalAddress,
  mailingAddress: dummyData.mailingAddress,
  contacts: dummyData.contacts,
  driverTypes: dummyData.driverTypes,
  truckTypes: dummyData.truckTypes,
  trailerTypes: dummyData.trailerTypes,
  hos: dummyData.hos,
  documents: dummyData.documents,
  moreDetails: dummyData.moreDetails,
  status: 'IN_PROGRESS',
};

export default function registration(state = defaultState, action) {
  switch (action.type) {
    case TYPES.CARRIER_REGISTER_ID:
      return {
        ...state,
        registerId: action.value,
      };
    case TYPES.CARRIER_STATUS:
      return {
        ...state,
        status: action.value,
      };
    case TYPES.CARRIER_GENERAL:
      return {
        ...state,
        general: action.value,
      };
    case TYPES.CARRIER_PA:
      return {
        ...state,
        physicalAddress: action.value,
      };
    case TYPES.CARRIER_MA:
      return {
        ...state,
        mailingAddress: action.value,
      };
    case TYPES.CARRIER_CONTACT:
      return {
        ...state,
        contacts: action.value,
      };
    case TYPES.CARRIER_DRIVERS:
      return {
        ...state,
        driverTypes: action.value,
      };

    case TYPES.CARRIER_TRUCKS:
      return {
        ...state,
        truckTypes: action.value,
      };
    case TYPES.CARRIER_TRAILERS:
      return {
        ...state,
        trailerTypes: action.value,
      };
    case TYPES.CARRIER_HOS:
      return {
        ...state,
        hos: action.value,
      };
    case TYPES.CARRIER_DOCS:
      return {
        ...state,
        documents: action.value,
      };
    case TYPES.CARRIER_MORE_DETAILS:
      return {
        ...state,
        moreDetails: action.value,
      };

    default:
      return state;
  }
}
