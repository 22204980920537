import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import AppHome from "./AppHome";
// import AppDriver from "./AppDriver";
// import AppCarrier from "./AppCarrier";
import HomeData from './containers/Home/HomeData';
import ContactUs from './containers/ContactUs/ContactUs';

import "./App.css";

function App() {
    return (
        <BrowserRouter>
            <div>
                <Switch>
                    <Route exact path="/">
                        <AppHome />
                    </Route>
                    {/* <Route path="/driver/:DOTno">
                        <AppDriver />
                    </Route> */}
                    <Route path="/:DOTno/home">
                        <HomeData />
                    </Route>
                    <Route path="/:DOTno/contact">
                        <ContactUs />
                    </Route>
                </Switch>
            </div>
        </BrowserRouter>
    );
}
export default App;
