import * as cookie from "react-cookie";

function getDomain() {
    if (process.env.REACT_APP_DOMAIN === "localhost") {
        return "localhost";
    }
    const url = window.location.hostname;
    let hostname = url.split(".").slice(-2);
    return `*.${hostname.join(".")}`;
}

const URLS = {
    NEXT_API: process.env.REACT_APP_NEXT_API,
    CORE_API: process.env.REACT_APP_CORE_API,
    CF_API: process.env.REACT_APP_CF_API
};

const cookies = new cookie.Cookies();

const getAccessToken = function getAccessToken() {
    return cookies.get("token", {
        path: "/",
        domain: getDomain()
    });
};

const getJwtToken = function getJwtToken() {
    return cookies.get("jwtToken", {
        path: "/",
        domain: getDomain()
    });
};

const getBusinessId = function getAccessToken() {
    return cookies.get("businessId", {
        path: "/",
        domain: getDomain()
    });
};

const getUserId = function getAccessToken() {
    return cookies.get("userId", {
        path: "/",
        domain: getDomain()
    });
};

const getIP = function getAccessToken() {
    return cookies.get("ipAddress", {
        path: "/",
        domain: getDomain()
    });
};

// const pdfOptions = {
//     enableLinks: true,
//     margin: [0.5, 0.5, 0.5, 0.5],
//     image: { type: "jpeg", quality: 0.98 },
//     html2canvas: {
//         scale: 2,
//         dpi: 192,
//         letterRendering: true,
//         useCORS: true
//     },
//     jsPDF: {
//         unit: "in",
//         format: "letter",
//         orientation: "portrait",
//         compressPDF: true
//     }
// };

const pdfOptions = {
    enableLinks: true,
    margin: [0.7, 0.5, 0.8, 0.5],
    image: { type: "jpeg", quality: 0.98 },
    html2canvas: {
        scale: 2,
        dpi: 192,
        letterRendering: true,
        useCORS: true
    },
    jsPDF: {
        unit: "in",
        format: "letter",
        orientation: "portrait",
        compressPDF: true
    }
};

export {
    URLS,
    getAccessToken,
    getJwtToken,
    getDomain,
    getBusinessId,
    getUserId,
    getIP,
    pdfOptions
};
