import * as TYPES from "./appActionTypes";

const defaultState = {
    business: {},
    googleUser: {},
    recruitingType: ""
};

export default function app(state = defaultState, action) {
    switch (action.type) {
        case TYPES.BUSINESS:
            return {
                ...state,
                business: action.value
            };
        case TYPES.GOOGLE_USER:
            return {
                ...state,
                googleUser: action.value,
                isSignInOpen: !action.value.email
            };
        case TYPES.RECRUITING_TYPE:
            return {
                ...state,
                recruitingType: action.value
            };
        default:
            return state;
    }
}
