import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Jumbotron, Container, Row, Col } from "reactstrap";
import { Button, Label, Input, Alert } from "reactstrap";
import _get from "lodash/get";
import { getBusiness } from "../../actions/app/appAction";

export class DriverAppSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dot: "",
            mc: "",
            isMc: false,
            isDot: false,
            loading: false
        };
    }

    getBusiness = async () => {
        const { dot, mc,business } = this.state;
        this.setState({ loading: true, error: false });
        try {
            const business = await this.props.getBusiness(dot, mc);
            if (business.id) {
                this.setState({ loading: false, business, isOpen: true });
            } else {
                this.setState({ loading: false, error: true, business: null });
            }
        } catch (e) {
            console.log(e);
            this.setState({ loading: false, error: true, business: null });
        }
  if(business){
       const url = 
            `/${dot}/home`
        window.location.replace(url);
  }
    };

    render() {
        const { dot, mc, error, loading } = this.state;
        return (
            <div>
        
                <Jumbotron fluid style={{ backgroundColor: "white" }}>
                    <Container fluid>
                        <Row>
                            <Col md="4" />
                            <Col
                                md="4"
                                className="text-center"
                                style={{ margin: "25px auto" }}
                            >
                                <h2 className="text-center text-uppercase">
                                    JOIN WITH US
                                </h2>
                                <p>
                                    We are excited to see you are interested in
                                    working with us.
                                </p>
                                <div style={{ marginTop: 15, fontWeight: 600 }}>
                                    How would you like work driver or carrier ?
                                </div>
                              
                                <Label
                                    for="examplePassword"
                                    className="mr-sm-2 sr-only"
                                >
                                    Dot
                                </Label>
                                    <Input
                                        bsSize="sm"
                                        type="number"
                                        name="dot"
                                        value={dot}
                                        id="dot"
                                        placeholder="Enter DOT number of carrier, with whom you want to drive."
                                        className="btn-block mt-3"
                                        onChange={e => {
                                            this.setState({
                                                dot: e.target.value,
                                            });
                                        }}
                                    />
                                {error && (
                                    <Alert
                                        color="danger"
                                        isOpen={true}
                                        fade={false}
                                        style={{ marginTop: 5 }}
                                    >
                                        Not able to find.
                                    </Alert>
                                )}
                                <Row>
                                    <Col md="12">
                                        <Button
                                            size="sm"
                                            color="info"
                                            className="btn-block"
                                            disabled={!(dot || mc) || loading}
                                            style={{ marginTop: 25 }}
                                            onClick={this.getBusiness}
                                        >
                                            SEARCH
                                        </Button>
                                    </Col>
               
                                </Row>
                            </Col>
                            <Col md="4" />
                        </Row>
                    </Container>
                </Jumbotron>
             
            </div>
        );
    }
}
const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", ""),
        isSignInOpen: _get(state, "appData.isSignInOpen", true)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getBusiness
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(DriverAppSearch)
);
