import React, { Component } from 'react';
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Jumbotron, Button, Row, Col } from 'reactstrap';
import _get from "lodash/get";
import { getBusiness } from "../../actions/app/appAction";


class HomeData extends Component {

  constructor(props) {
    super(props);
    this.state = {

    };
  }

  async componentDidMount() {

    const url_string = window.location.href;
    const url = new URL(url_string);
    const queryDot = url.pathname.split('/')[1];
    console.log(queryDot);
    this.setState({ dot: queryDot })
    const { dot } = this.state;
    console.log("dot", dot)
    // this.setState({ loading: true, error: false });

    try {
      const business = await this.props.getBusiness(queryDot);
      if (business.id) {
        this.setState({ loading: false, business, isOpen: true });
      } else {
        this.setState({ loading: false, error: true, business: null });
      }
    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true, business: null });
    }

  };

  handleSubmit = () => {

    const { dot } = this.props;
    const url = `/${dot}/contact`;
    window.location.replace(url);

  }
  render() {
    const { business } = this.props;

    return (
      <div className="text-center" >
        <Jumbotron
          style={{ margin: "50px" }}
        >
          <h3 className="display-3 mb-5 "> DOT Data.</h3>
          <hr className="my-2 mt-2" />
          <Row style={{ marginTop: "70px" }}>
            <Col>



              <p>
                <strong>DOT No :</strong> {business && business.dot}
              </p>
              <p>
                <strong>MC No :</strong> {business && business.mc}
              </p>
              <p>
                <strong>Name :</strong> {business && business.name}
              </p>
              <p>
                <strong>Driver's Age :</strong> {business && business.driverAge}
              </p>
              <p>
                <strong>Email :</strong> {business && business.Email}
              </p>
              <p>
                <strong>Phone :</strong> {business && business.phone}
              </p>
             

            </Col>
            <Col>
 <p>
                <strong>Address Line1 :</strong> {business && business.line1}
              </p>
              <p>
                <strong>Address Line2 :</strong> {business && business.line2}
              </p>
              <p>
                <strong>City :</strong> {business && business.city}
              </p>
              <p>
                <strong>Zip :</strong> {business && business.zip}
              </p>
              <p>
                <strong>State :</strong> {business && business.state}
              </p>
              <p>
                <strong>Country :</strong> {business && business.country}
              </p>



            </Col>
          </Row>
          <p className="lead mt-5">

            <Button color="primary"
              onClick={this.handleSubmit}>CONTACT US</Button>
          </p>
        </Jumbotron>
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    business: _get(state, "appData.business", {}),
    userEmail: _get(state, "appData.googleUser.email", ""),
    isSignInOpen: _get(state, "appData.isSignInOpen", true)
  };
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      getBusiness
    },
    dispatch
  );

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(HomeData)
);