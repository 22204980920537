import * as TYPES from "./actionTypes";
import { dummyData } from "./dummyData";

const defaultState = {
    registerDriver: dummyData.registerDriver,
    personal: dummyData.personal,
    personalAddress: dummyData.personalAddress,
    general: dummyData.general,
    license: dummyData.license,
    violation: dummyData.violation,
    accident: dummyData.accident,
    military: dummyData.military,
    employment: dummyData.employment,
    training: dummyData.training,
    unemployment: dummyData.unemployment,
    mvr: dummyData.mvr,
    hos: dummyData.hos,
    fmcsr: dummyData.fmcsr,
    review: dummyData.review,
    status: "IN_PROGRESS"
};

export default function registration(state = defaultState, action) {
    switch (action.type) {
        case TYPES.REGISTER_ID:
            return {
                ...state,
                registerId: action.value
            };
        case TYPES.REGISTER_STATUS:
            return {
                ...state,
                status: action.value
            };
        case TYPES.REGISTER_DRIVER:
            return {
                ...state,
                registerDriver: action.value
            };
        case TYPES.REGISTER_DRIVER_INFO:
            return {
                ...state,
                [action.key]: action.value
            };
        case TYPES.REGISTER_DRIVER_PERSONAL:
            return {
                ...state,
                personal: action.value
            };
        case TYPES.REGISTER_DRIVER_PERSONAL_ADDRESS:
            return {
                ...state,
                personalAddress: action.value
            };
        case TYPES.REGISTER_DRIVER_GENERAL:
            return {
                ...state,
                general: action.value
            };
        case TYPES.REGISTER_DRIVER_LICENSE:
            return {
                ...state,
                license: action.value
            };
        case TYPES.REGISTER_DRIVER_MILITARY:
            return {
                ...state,
                military: action.value
            };
        case TYPES.REGISTER_DRIVER_EMPLOYED:
            return {
                ...state,
                employment: action.value
            };
        case TYPES.REGISTER_DRIVER_TRAINING:
            return {
                ...state,
                training: action.value
            };
        case TYPES.REGISTER_DRIVER_HOS:
            return {
                ...state,
                hos: action.value
            };
        case TYPES.REGISTER_DRIVER_UNEMPLOYED:
            return {
                ...state,
                unemployment: action.value
            };
        case TYPES.REGISTER_DRIVER_FMCSR:
            return {
                ...state,
                fmcsr: action.value
            };
        case TYPES.REGISTER_DRIVER_VIOLATION:
            return {
                ...state,
                violation: action.value
            };
        case TYPES.REGISTER_DRIVER_ACCIDENT:
            return {
                ...state,
                accident: action.value
            };
        case TYPES.REGISTER_DRIVER_MVR:
            return {
                ...state,
                mvr: action.value
            };
        default:
            return state;
    }
}
