// import _get from "lodash/get";
import * as TYPES from "./docActionTypes";
const defaultState = {
    loading: false,
    busienss: {},
    mainBusiness: {},
    driver: {},
    drivers: [],
    carriers: [],
    carrier: {},
    trailers: [],
    trailer: {},
    alias: []
};

export default function business(state = defaultState, action) {
    switch (action.type) {
        case TYPES.DOC_LOADING:
            return {
                ...state,
                loading: action.value
            };
        case TYPES.MAIN_BUSINESS:
            return {
                ...state,
                mainBusiness: action.value
            };
        case TYPES.DOC_BUSINESS:
            return {
                ...state,
                business: action.value
            };
        case TYPES.DOC_BUSINESS_ALIAS:
            return {
                ...state,
                alias: action.value
            };
        case TYPES.DOC_DRIVERS:
            return {
                ...state,
                drivers: action.value
            };
        case TYPES.DOC_DRIVER:
            return {
                ...state,
                driver: action.value
            };
        case TYPES.DOC_CARRIERS:
            return {
                ...state,
                carriers: action.value
            };
        case TYPES.DOC_CARRIER:
            return {
                ...state,
                carrier: action.value
            };
        case TYPES.DOC_TRAILERS:
            return {
                ...state,
                trailers: action.value
            };
        case TYPES.DOC_TRAILER:
            return {
                ...state,
                trailer: action.value
            };

        default:
            return state;
    }
}
