export const LOADING = "LOADING";
export const RECRUITING = "RECRUITING";

export const REGISTER_ID = 'REGISTER_ID';
export const REGISTER_STATUS = 'REGISTER_STATUS';
export const REGISTER_DRIVER = 'REGISTER_DRIVER';
export const REGISTER_DRIVER_INFO = 'REGISTER_DRIVER_INFO';

export const REGISTER_DRIVER_PERSONAL = 'REGISTER_DRIVER_PERSONAL';
export const REGISTER_DRIVER_PERSONAL_ADDRESS = 'REGISTER_DRIVER_PERSONAL_ADDRESS';

export const REGISTER_DRIVER_HOS = 'REGISTER_DRIVER_HOS';
export const REGISTER_DRIVER_MVR = 'REGISTER_DRIVER_MVR';
export const REGISTER_DRIVER_GENERAL = 'REGISTER_DRIVER_GENERAL';
export const REGISTER_DRIVER_LICENSE = 'REGISTER_DRIVER_LICENSE';
export const REGISTER_DRIVER_VIOLATION = 'REGISTER_DRIVER_VIOLATION';
export const REGISTER_DRIVER_ACCIDENT = 'REGISTER_DRIVER_ACCIDENT';
export const REGISTER_DRIVER_MILITARY = 'REGISTER_DRIVER_MILITARY';
export const REGISTER_DRIVER_EMPLOYED = 'REGISTER_DRIVER_EMPLOYED';
export const REGISTER_DRIVER_TRAINING = 'REGISTER_DRIVER_TRAINING';
export const REGISTER_DRIVER_UNEMPLOYED = 'REGISTER_DRIVER_UNEMPLOYED';
export const REGISTER_DRIVER_FMCSR = 'REGISTER_DRIVER_FMCSR';