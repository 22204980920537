import React from "react";
import { Jumbotron } from "reactstrap";
import DriverAppSearch from "./DriverAppSearch";

const Home = props => {
    return (
        <div>
            <Jumbotron fluid style={{ backgroundColor: "white" }}>
                <DriverAppSearch />
            </Jumbotron>
        </div>
    );
};

export default Home;
