import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import { Col, Input, Row, Button, Jumbotron } from "reactstrap";
import _get from "lodash/get";
import { Formik } from "formik";
import { sendEmail } from "../../actions/registration/registrationActions";
class ContactUs extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    validate = values => {
        const errors = {};
        if (!values.firstName) {
            errors.firstName = "First name is required.";
        } else if (values.firstName.length < 4) {
            errors.firstName = "First name too short.";
        }

        if (!values.lastName) {
            errors.lastName = "Last name is required.";
        } else if (values.lastName.length < 4) {
            errors.lastName = "First name too short.";
        }

        if (!values.phoneNo) {
            errors.phoneNo = "Phone number is required.";
        } else if (values.phoneNo.length < 10 || values.phoneNo.length > 11) {
            errors.phoneNo = "Invalid phone number.";
        }

        if (!values.email) {
            errors.email = "Email name is required.";
        } else if (
            !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        ) {
            errors.email = "Invalid email address.";
        }

        if (!values.message) {
            errors.message = "Message is required.";
        } else if (values.message.length < 10) {
            errors.message = "Message too short.";
        }
        return errors;
    };

    submitData = async inpvalues => {
        const contact = inpvalues || {};
        const payload = {
            fullname: `${contact.firstName} ${contact.lastName}`,
            email: contact.email,
            phonenumber: contact.phonenumber,
            subject: "Contact us from www.osrit.com",
            message: contact.message,
            dateadded: new Date()
        };
        const response = await this.props.sendEmail(payload);
        const isSuccess = _get(response, "message.body.status") === "success";
        this.setState({
            success: isSuccess,
            error: !isSuccess,
            response
        });
    };

    render() {
        return (
            <>
                <Jumbotron>
                    <Formik
                        initialValues={{
                            firstName: "",
                            lastName: "",
                            email: "",
                            phoneNo: "",
                            subject: "Contact us from www.eldbooks.com",
                            message: "",
                            dateadded: new Date()
                        }}
                        validate={this.validate}
                        onSubmit={(values, { setSubmitting, resetForm }) => {
                            setSubmitting(true);
                            setTimeout(() => {
                                resetForm();
                                setSubmitting(false);
                            }, 500);
                            this.submitData(values);
                        }}
                    >
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            isSubmitting,
                            setFieldValue
                        }) => (
                            <form
                                className="contactform"
                                onSubmit={handleSubmit}
                            >
                                <div
                                    style={{
                                        padding: "100px",
                                        width: "70%",
                                        margin: "auto"
                                    }}
                                >
                                    <h2
                                        style={{
                                            textAlign: "center",
                                            marginTop: "50px",
                                            marginBottom: "40px"
                                        }}
                                    >
                                        Contact us
                                    </h2>

                                    <Row style={{ alignItems: "center" }}>
                                        <Col md={6} lg={6} sm={6} xs={6}>
                                            <Input
                                                style={{
                                                    borderColor: errors.firstName
                                                        ? "red"
                                                        : null
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.firstName}
                                                name="firstName"
                                                id="name"
                                                placeholder="First Name*"
                                            />
                                            {
                                                <div
                                                    style={{
                                                        color: "red",
                                                        border: "red"
                                                    }}
                                                >
                                                    {errors.firstName}
                                                </div>
                                            }
                                        </Col>
                                        <Col md={6} lg={6} sm={6} xs={6}>
                                            <Input
                                                style={{
                                                    borderColor: errors.lastName
                                                        ? "red"
                                                        : null
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lastName}
                                                name="lastName"
                                                id="name"
                                                placeholder="Last Name*"
                                                required
                                            />
                                            {
                                                <div style={{ color: "red" }}>
                                                    {errors.lastName}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <Input
                                                style={{
                                                    borderColor: errors.phoneNo
                                                        ? "red"
                                                        : null
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.phoneNo}
                                                name="phoneNo"
                                                id="Phone"
                                                type="number"
                                                placeholder="Phone*"
                                                required
                                            />
                                            {
                                                <div style={{ color: "red" }}>
                                                    {errors.phoneNo}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <Input
                                                style={{
                                                    borderColor: errors.email
                                                        ? "red"
                                                        : null
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.email}
                                                name="email"
                                                id="Email"
                                                type="email"
                                                placeholder="Email*"
                                                required
                                            />
                                            {
                                                <div style={{ color: "red" }}>
                                                    {errors.email}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col>
                                            <Input
                                                style={{
                                                    borderColor: errors.message
                                                        ? "red"
                                                        : null
                                                }}
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.message}
                                                name="message"
                                                id="Message"
                                                placeholder="Enter Message*"
                                                required
                                            />
                                            {
                                                <div style={{ color: "red" }}>
                                                    {errors.message}
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                    {/* {this.state.response ?
                                            <Alert style={{marginTop:"30px"}}
                                             color="success">
                                               Form submitted successfully
                                            </Alert>
                                            :<Alert style={{marginTop:"30px"}}
                                             color="danger">
                                               Something went wrong!
                                            </Alert>} */}
                                    <div
                                        style={{
                                            textAlign: "center",
                                            marginBottom: 38,
                                            marginTop: 30
                                        }}
                                    >
                                        <Button
                                            className="button"
                                            color="primary"
                                            type="submit"
                                        >
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        )}
                    </Formik>

                    {/* <Footer /> */}
                </Jumbotron>
            </>
        );
    }
}
const mapStateToProps = state => {
    return {
        business: _get(state, "appData.business", {}),
        userEmail: _get(state, "appData.googleUser.email", ""),
        isSignInOpen: _get(state, "appData.isSignInOpen", true)
    };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            sendEmail
            //   getBusiness
        },
        dispatch
    );

export default withRouter(
    connect(
        mapStateToProps,
        mapDispatchToProps
    )(ContactUs)
);
