export const LOADING = 'LOADING';
export const CARRIER = 'CARRIER';
export const REGISTER_CARRIER = 'REGISTER_CARRIER';

export const CARRIER_REGISTER_ID = 'CARRIER_REGISTER_ID';
export const CARRIER_STATUS = 'CARRIER_STATUS';

export const CARRIER_GENERAL = 'CARRIER_GENERAL';
export const CARRIER_CONTACT = 'CARRIER_CONTACT';
export const CARRIER_PA = 'CARRIER_P_ADDRESS';
export const CARRIER_MA = 'CARRIER_M_ADDRESS';
export const CARRIER_DRIVERS = 'CARRIER_DRIVERS';
export const CARRIER_TRUCKS = 'CARRIER_TRUCKS';
export const CARRIER_TRAILERS = 'CARRIER_TRAILERS';
export const CARRIER_HOS = 'CARRIER_HOS';
export const CARRIER_DOCS = 'CARRIER_DOCS';
export const CARRIER_MORE_DETAILS = 'CARRIER_MORE_DETAILS';
