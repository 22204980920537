export const dummyData = {
    general: {},
    physicalAddress: {
        line1: "",
        line2: "",
        city: "",
        state: "",
        zip: "",
        country: "US"
    },
    mailingAddress: {
        line1: "1122 S Main St",
        line2: "",
        city: "Stockton",
        state: "CA",
        zip: "973636",
        country: "US"
    },
    contacts: [],
    driverTypes: [],
    truckTypes: [],
    trailerTypes: [],
    hos: [],
    documents: [],
    moreDetails: {}
};
