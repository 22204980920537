import axios from "axios";
import { URLS } from "../../constants";

const axiosInstance = axios.create({
    baseURL: URLS.CORE_API
});

axiosInstance.defaults.timeout = 10000;
axiosInstance.defaults.headers.put["Content-Type"] = "application/json";
axiosInstance.defaults.headers.post["Content-Type"] = "application/json";
axiosInstance.interceptors.request.use(function requestInterceptor(config) {
    return config;
});

export default axiosInstance;
