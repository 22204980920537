import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { store } from "./store/configureStore";
import App from "./App";
import * as Sentry from "@sentry/browser";

import "./App.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";

const sentryDns = process.env.REACT_APP_SENTRY_DNS

if (process.env.NODE_ENV === "production" && sentryDns) {
    console.log(" **** SENTRY INIT **** ");
    Sentry.init({
        dsn: sentryDns
    });
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById("root")
);
