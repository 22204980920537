import { combineReducers } from 'redux';
import doc from './doc/docReducers';
import app from './app/appReducers';
import search from './search/searchReducers';
import registration from './registration/registrationReducers';
import carrier from './carrier/carrierReducers';

export default combineReducers({
  registrationData: registration,
  carrierData: carrier,
  docData: doc,
  appData: app,
  searchData: search,
});
